import { combineReducers } from "@reduxjs/toolkit"; // 사용자 리듀서(직접 만든 것들)
import global from "./global";
import music from "./music";
import chat from "./chat";
import exhibition from "./exhibition";
import collection from "./collection";
import room from "./room";
import menu from "./menu";
import layer from "./layer";
import chatRoom from "./chatRoom";
const rootReducer = combineReducers({
    global, music,
    chatting: chat, exhibition, collection, room, menu, layer, chatRoom
});
export default rootReducer;
