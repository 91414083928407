// 편집 시 활용하는 레이어 대한 스토어
// 오브젝트는 레이어에 종속되어 있으며, 레이어는 전시에 종속되어 있다.
import { enableAllPlugins } from "immer";
import { produce } from "immer";
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus // resetStatus,
 } from "../utils/reducerUtils";
enableAllPlugins(); // 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export const initialState = { defaultLayers: [], layers: [], currentLayers: [], getAllLayersStatus: createStatus(), getDefaultLayersStatus: createStatus() }; // 액션들의 집합.
export const actions = { GET_ALL_LAYERS_REQUEST: "GET_ALL_LAYERS_REQUEST", GET_ALL_LAYERS_SUCCESS: "GET_ALL_LAYERS_SUCCESS", GET_ALL_LAYERS_FAILURE: "GET_ALL_LAYERS_FAILURE", SET_CURRENT_LAYERS: "SET_CURRENT_LAYERS", TOGGLE_LAYER: "TOGGLE_LAYER", GET_DEFAULT_LAYERS_REQUEST: "GET_DEFAULT_LAYERS_REQUEST", GET_DEFAULT_LAYERS_SUCCESS: "GET_DEFAULT_LAYERS_SUCCESS", GET_DEFAULT_LAYERS_FAILURE: "GET_DEFAULT_LAYERS_FAILURE" }; // 액션 크리에이터. dispatch 내부에서 사용.
// 모든 오브젝트를 가져오는 액션. by는 어떤 기준으로 가져올지를 정한다.
export const getAllLayersAction = id => ({ type: actions.GET_ALL_LAYERS_REQUEST, id // exhibitionId
});
export const getDefaultLayersAction = id => ({ type: actions.GET_DEFAULT_LAYERS_REQUEST, id // exhibitionId
});
export const setCurrentLayersAction = ids => ({ type: actions.SET_CURRENT_LAYERS, ids });
export const toggleLayerAction = id => ({ type: actions.TOGGLE_LAYER, id }); // 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
const reducer = function () { let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState; let action = arguments.length > 1 ? arguments[1] : undefined; return produce(state, draft => { switch (action.type) {
    case actions.SET_CURRENT_LAYERS:
        draft.currentLayers = action.ids;
        break;
    case actions.TOGGLE_LAYER:
        const index = draft.currentLayers.findIndex(id => id === action.id);
        if (index === -1) {
            draft.currentLayers.push(action.id);
        }
        else {
            draft.currentLayers.splice(index, 1);
        }
        break;
    case actions.GET_ALL_LAYERS_REQUEST:
        draft.getAllLayersStatus = createRequestStatus();
        break;
    case actions.GET_ALL_LAYERS_SUCCESS:
        draft.getAllLayersStatus = createSuccessStatus();
        draft.layers = action.data;
        break;
    case actions.GET_ALL_LAYERS_FAILURE:
        draft.getAllLayersStatus = createFailureStatus(action.error);
        break;
    case actions.GET_DEFAULT_LAYERS_REQUEST:
        draft.getDefaultLayersStatus = createRequestStatus();
        break;
    case actions.GET_DEFAULT_LAYERS_SUCCESS:
        draft.getDefaultLayersStatus = createSuccessStatus();
        draft.defaultLayers = action.data;
        break;
    case actions.GET_DEFAULT_LAYERS_FAILURE:
        draft.getDefaultLayersStatus = createFailureStatus(action.error);
        break;
    default: break;
} }); };
export default reducer;
