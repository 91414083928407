import { all, fork, put, takeEvery } from "redux-saga/effects";
import { actions } from "../reducers/layer";
import axios from "axios"; // call은 동기, fork는 비동기 요청
function* getAllLayers(action) {
    const { id } = action;
    try {
        const res = yield axios.post("https://asia-northeast3-onna-b7783.cloudfunctions.net/getLayerData", // "http://127.0.0.1:5001/onna-b7783/asia-northeast3/getLayerData",
        { id });
        const data = res.data.data;
        yield put({ type: actions.GET_ALL_LAYERS_SUCCESS, data });
    }
    catch (err) {
        yield put({ type: actions.GET_ALL_LAYERS_FAILURE, error: err.message });
    }
}
function* getDefaultLayerAction(action) { const { id } = action; try {
    const res = yield axios.post("https://asia-northeast3-onna-b7783.cloudfunctions.net/getDefaultLayersData", { id });
    const data = res.data.data;
    yield put({ type: actions.GET_DEFAULT_LAYERS_SUCCESS, data });
}
catch (err) {
    yield put({ type: actions.GET_DEFAULT_LAYERS_FAILURE, error: err.message });
} }
function* watchGetAllLayers() { yield takeEvery(actions.GET_ALL_LAYERS_REQUEST, getAllLayers); }
function* watchGetDefaultLayer() { yield takeEvery(actions.GET_DEFAULT_LAYERS_REQUEST, getDefaultLayerAction); }
export default function* layerSaga() { yield all([fork(watchGetAllLayers), fork(watchGetDefaultLayer)]); }
