import axios from 'axios';
import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { actions } from '../reducers/room'; // call은 동기, fork는 비동기 요청
function* getRooms(action) { const { exhibitionId } = action; try {
    const res = yield axios.post('https://asia-northeast3-onna-b7783.cloudfunctions.net/getRoomData', { id: exhibitionId });
    const data = res.data.data;
    yield put({ type: actions.GET_ROOMS_SUCCESS, data });
}
catch (err) {
    yield put({ type: actions.GET_ROOMS_FAILURE, error: err.message });
} }
function* watchGetRoom() { yield takeLatest(actions.GET_ROOMS_REQUEST, getRooms); }
export default function* globalSaga() { yield all([fork(watchGetRoom)]); }
